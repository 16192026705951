import React from "react"  

export default function Video({ idVideo='', videoTitle, ...props }) {
    return (
        <div className="video">
            <iframe
                src={`https://www.youtube.com/embed/${idVideo}`}
                title={videoTitle}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen 
            />
        </div>
    )
}
