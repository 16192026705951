import moment from "moment"; 
import { timesData } from "../data";

const getTimeAllowed=(datetimeValue,addHour=0,initDate=new Date(),initTime='',isEndTime=false)=>{ 
    return timesData.filter((item)=>{
        const dateNow = moment(datetimeValue).format('yyyy-MM-DD');
        const timeNow = item;
        const datetime = moment(`${dateNow} ${timeNow}`); 
        if(isEndTime){
            const initDatetime = `${moment(initDate).format('yyyy-MM-DD')} ${initTime||moment().format('HH:mm')}`;
            return datetime.isSameOrAfter(moment(initDatetime).add(addHour,'hours'))&&datetime.isBefore(moment(initDatetime).add(24,'hours'));
        }else{
            const initDatetime = `${moment(initDate).format('yyyy-MM-DD')} ${initTime||moment().format('HH:mm')}`;
            return datetime.isSameOrAfter(moment(initDatetime).add(addHour,'hours'));
        }
    }); 
}

export {
    getTimeAllowed
}